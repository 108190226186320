import { createSlice, Dispatch, type PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "./store";
import tkbApi from "../api/tkbApi";
import { ClientLoanTemplate } from "../modules/Loan/types/response/ClientLoanTemplate";
import { CollateralTemplate } from "../modules/Loan/types/response/CollateralTemplate";
import { RepaymentResponse } from "../modules/Loan/types/response/Repayment";
import { MemberTemplate } from "../modules/Members/types/MemberTemplate";
import { Member } from "../modules/Members/types/response/ListMembersResponse";
import { ClientAccounts } from "../modules/Savings/types/response/ListClientSavings";
import { SavingAccount } from "../modules/Savings/types/response/ListSavings";
import { TLoanApplicationTemplate } from "../modules/Loan/types/response/ApplicationTemplate";
import { LoanProduct } from "../modules/Loan/types/response/LoanProduct";
import { ChargeTemplate } from "../modules/Loan/types/response/ChargeTemplate";
import { GuarantorTemplate } from "../modules/Loan/types/response/GuarantorTemplate";
import { ExtraClientDetails } from "../modules/Members/types/data-tables/ExtraClientDetails";
import { PasswordFile } from "../components/shared/FileUpload";

export interface LoanRecepient {
  id: number;
  accountNo: string;
  externalId: string;
  status: LegalForm;
  subStatus: LoanClientClassification;
  active: boolean;
  activationDate: number[];
  firstname: string;
  lastname: string;
  middlename: string;
  displayName: string;
  mobileNo: string;
  dateOfBirth: number[];
  gender: LoanClientClassification;
  clientType: LoanClientClassification;
  clientClassification: LoanClientClassification;
  isStaff: boolean;
  officeId: number;
  officeName: string;
  imageId: number;
  imagePresent: boolean;
  timeline: Timeline;
  legalForm: LegalForm;
  clientNonPersonDetails: ClientNonPersonDetails;
}
export interface LoanClientClassification {
  id: number;
  name: string;
  active: boolean;
  mandatory: boolean;
}
export interface ClientNonPersonDetails {
  constitution: LoanClientClassification;
  mainBusinessLine: LoanClientClassification;
}
export interface LegalForm {
  id: number;
  code: string;
  value: string;
}
export interface Timeline {
  submittedOnDate: number[];
  submittedByUsername: string;
  submittedByFirstname: string;
  submittedByLastname: string;
  activatedOnDate: number[];
  activatedByUsername: string;
  activatedByFirstname: string;
  activatedByLastname: string;
}
export interface AccountingRule {
  id: number;
  code: string;
  value: string;
}
export interface AllowAttributeOverrides {
  amortizationType: boolean;
  interestType: boolean;
  transactionProcessingStrategyId: boolean;
  interestCalculationPeriodType: boolean;
  inArrearsTolerance: boolean;
  repaymentEvery: boolean;
  graceOnPrincipalAndInterestPayment: boolean;
  graceOnArrearsAgeing: boolean;
}
export interface Currency {
  code: string;
  name: string;
  decimalPlaces: number;
  inMultiplesOf: number;
  displaySymbol: string;
  nameCode: string;
  displayLabel: string;
}
export interface LoanParticulars {
  principal: number;
  numberOfRepayments: number;
  typeOfLoan?: string;
  subSector1?: string;
  subSector2?: string;
  loanRepaymentMethod?: string;
  loanDisbursementMethod?: string;
  notes?: string;
}
export interface Loan {
  id: number;
  accountNo: string;
  externalId: string;
  status: Status;
  clientId: number;
  clientAccountNo: string;
  clientName: string;
  clientOfficeId: number;
  loanProductId: number;
  loanProductName: string;
  loanProductDescription: string;
  isLoanProductLinkedToFloatingRate: boolean;
  fundId: number;
  fundName: string;
  loanPurposeId: number;
  loanPurposeName: string;
  loanOfficerId: number;
  loanOfficerName: string;
  loanType: Type;
  currency: Currency;
  principal: number;
  approvedPrincipal: number;
  proposedPrincipal: number;
  termFrequency: number;
  termPeriodFrequencyType: Type;
  numberOfRepayments: number;
  repaymentEvery: number;
  repaymentFrequencyType: Type;
  interestRatePerPeriod: number;
  interestRateFrequencyType: Type;
  annualInterestRate: number;
  isFloatingInterestRate: boolean;
  amortizationType: Type;
  interestType: Type;
  interestCalculationPeriodType: Type;
  allowPartialPeriodInterestCalcualtion: boolean;
  transactionProcessingStrategyId: number;
  transactionProcessingStrategyName: string;
  graceOnArrearsAgeing: number;
  interestChargedFromDate: number[];
  expectedFirstRepaymentOnDate: number[];
  syncDisbursementWithMeeting: boolean;
  timeline: Timeline;
  summary: Summary;
  feeChargesAtDisbursementCharged: number;
  loanCounter: number;
  loanProductCounter: number;
  multiDisburseLoan: boolean;
  canDefineInstallmentAmount: boolean;
  canDisburse: boolean;
  canUseForTopup: boolean;
  isTopup: boolean;
  closureLoanId: number;
  inArrears: boolean;
  isNPA: boolean;
  daysInMonthType: Type;
  daysInYearType: Type;
  isInterestRecalculationEnabled: boolean;
  createStandingInstructionAtDisbursement: boolean;
  isVariableInstallmentsAllowed: boolean;
  minimumGap: number;
  maximumGap: number;
  isEqualAmortization: boolean;
  isRatesEnabled: boolean;
}
export interface Type {
  id: number;
  code: string;
  value: string;
}
export interface Currency {
  code: string;
  name: string;
  decimalPlaces: number;
  inMultiplesOf: number;
  displaySymbol: string;
  nameCode: string;
  displayLabel: string;
}
export interface Status {
  id: number;
  code: string;
  value: string;
  pendingApproval: boolean;
  waitingForDisbursal: boolean;
  active: boolean;
  closedObligationsMet: boolean;
  closedWrittenOff: boolean;
  closedRescheduled: boolean;
  closed: boolean;
  overpaid: boolean;
}
export interface Summary {
  currency: Currency;
  principalDisbursed: number;
  principalPaid: number;
  principalWrittenOff: number;
  principalOutstanding: number;
  principalOverdue: number;
  interestCharged: number;
  interestPaid: number;
  interestWaived: number;
  interestWrittenOff: number;
  interestOutstanding: number;
  interestOverdue: number;
  feeChargesCharged: number;
  feeChargesDueAtDisbursementCharged: number;
  feeChargesPaid: number;
  feeChargesWaived: number;
  feeChargesWrittenOff: number;
  feeChargesOutstanding: number;
  feeChargesOverdue: number;
  penaltyChargesCharged: number;
  penaltyChargesPaid: number;
  penaltyChargesWaived: number;
  penaltyChargesWrittenOff: number;
  penaltyChargesOutstanding: number;
  penaltyChargesOverdue: number;
  totalExpectedRepayment: number;
  totalRepayment: number;
  totalExpectedCostOfLoan: number;
  totalCostOfLoan: number;
  totalWaived: number;
  totalWrittenOff: number;
  totalOutstanding: number;
  totalOverdue: number;
  totalRecovered: number;
}
export interface Timeline {
  submittedOnDate: number[];
  submittedByUsername: string;
  submittedByFirstname: string;
  submittedByLastname: string;
  approvedOnDate: number[];
  approvedByUsername: string;
  approvedByFirstname: string;
  approvedByLastname: string;
  expectedDisbursementDate: number[];
  actualDisbursementDate: number[];
  disbursedByUsername: string;
  disbursedByFirstname: string;
  disbursedByLastname: string;
  expectedMaturityDate: number[];
}

export interface LoanApplicationNotes {
  payoffNotes?: string;
  particularsNotes?: string;
}

export interface LoanGuarantee {
  clientId: number;
  accountNo: string;
  displayName: string;
  mainSavings: number;
  canGuaranteeUpto: number;
  amountToGuarantee: number;
}

export interface LoanCollateral {
  collateralTypeId: number;
  description?: string;
  name?: string;
  value?: string;
  file?: File;
  password?: string;
}

export interface LoanReferee {
  title?: string;
  name?: string;
  phone?: string;
  email?: string;
  idNumber?: string;
  status?: string;
  approvalDate?: string;
  joinedDate?: string;
  key?: string;
}

export interface LoanMemberOverride {
  title?: string;
  firstName?: string;
  externalId?: string;
  middleName?: string;
  lastName?: string;
  primaryPhoneNumber?: string;
  secondaryPhoneNumber?: string;
  emailAddress?: string;
  kraPIN?: string;
  nationalId?: string;
  gender?: number;
  maritalStatus?: string;
  dob?: Date;
  postalAddress?: string;
  physicalAddress?: string;
  employmentType?: string,
  personOfInterests?: string[],
  profession?: number,
  nameOfEmployer?: string,
  staffId?: string,
  monthlyAvailableForRepaymentAmount?: string;
  twoThirdRuleObserved?: string;
}

export interface LoanClientAccounts {
  clientId: number;
  clientAccounts: ClientAccounts
}

export type TLoanDetails = {
  id?: number;
  loanPuposeId?: number
  loanOfficerId?: number
  loanFundSourceId?: number
  submittedOn?: Date
  disbursementOn?: Date
  externalId?: string
  sector?: string;
  subSector?: string;
  loanProductId?: number
  savingsAccountId?: number
  createStandingInstruction?: boolean

  // terms
  principal?: number;
  loanTerms?: number;
  loanTermType?: number;
  noOfRepayments?: number
  repaymentEvery?: number
  repaymentFrequencyId?: number
  repaymentNthDayId?: number
  repaymentOnDayOfWeekId?: number
  repaymentStrategyId?: number | string
  firstRepaymentDate?: Date
  interestChargeFrom?: Date
  interestMethodId?: number
  nominalInterestRate?: number
  interestCalculationPeriodId?: number
  allowPartialPeriodInterestCalculation?: boolean
  interestFreePeriod?: number
  arrearsTolerance?: number
  isEqualAmotization?: boolean
  amotizartionId?: number
  moratoriumOnInterestPayment?: number | string
  moratoriumOnPrincipalPayment?: number | string
  moratoriumOnArreasAging?: number | string

  // loan document
  documentType?: string;
  bank?: string;
  password?: string;
  statementFile?: File | null;

  loanAppraisal?: TAppraisalDetails;

  submit?: null
}


export interface Fee {
  type: string;
  percentage: string;
  amount: number;
}

export interface LoanPayoff {
  amount: number;
  id: number;
  productName: string;
}

export type TAppraisalDetails = {
  // Employment Info
  employmentType?: string;
  professionId?: number;
  employmentDurationYears?: number | string;
  personOfInterests?: string[];
  nameOfEmployer?: string;
  staffId?: string;

  // Appraiser info
  appraiserNames?: string;
  appraiserUserId?: string;

  // Brief history
  businessType?: string;
  businessLocation?: string[];
  businessPIN?: string;
  businessHasPermit?: string;
  businessName?: string;
  businessDescription?: string;
  smeType?: string;
  numberOfDependants?: string;
  numberOfMaleEmployees?: string;
  numberOfFemaleEmployees?: string;
  womenLedOrganization?: string;

  // Business files
  files?: File[];

  // Impact
  economicEmpowerment?: string[]
  socialStabilityAndCommunityDevelopment?: string[]
  enhanceResilienceAndSelfReliance?: string[]
  broaderEconomicAndSocialBenefits?: string[]
  reasonAndDetailsOfImpact?: string

  // Income
  grossIncome?: string | number;
  basicSalary?: string | number;
  takeHome?: string | number;
  sourcesOfOtherIncome?: string[];
  otherIncomeAmount?: string | number;
  payslipPhoto?: PasswordFile[];
  mpesaStatement?: PasswordFile[];
  bankStatements?: PasswordFile[];
  otherSourcesPhoto?: PasswordFile[];
  incomeStatement?: PasswordFile[];
  salesTurnover?: PasswordFile[];
  operatingExpenses?: PasswordFile[];
  administrativeExpenses?: PasswordFile[];
  netProfit?: PasswordFile[];
  tax?: PasswordFile[];

  // Loan payoff
  payoffExistingLoan?: string;
  payoffs?: LoanPayoff[];
  loanRepayment?: string;
  bankName?: string;
  branch?: string;
  accountNumber?: string;

  // Fees
  fees?: Fee[]

  // Metadata
  created_at?: number[]
  updated_at?: number[]

  submit?: null
}

export type TLoanApprovalLevel1 = {
  recommendedAmount?: number;
  comments?: string;
  status?: string;
  conditions?: string;
}

export type TLoanApprovalLevel2 = {
  recommendedAmount?: number;
  comments?: string;
  status?: string;
  conditions?: string;
}

export type TLoanApprovalLevelFinal = {
  recommendedAmount?: number;
  comments?: string;
  status?: string;
  conditions?: string;
}

export interface LoanSlice {
  loans?: Loan[];
  loansCount?: number;
  loanRecipient?: LoanRecepient;
  loanRecipientAccounts?: ClientAccounts;
  clientExtraInfo?: ExtraClientDetails;
  loanProducts?: LoanProduct[];
  collateralTemplate?: CollateralTemplate;
  chargeTemplate?: ChargeTemplate;
  guarantorTemplate?: GuarantorTemplate;
  memberTemplate?: MemberTemplate;
  clientLoanTemplate?: ClientLoanTemplate;
  selectedLoanProduct?: LoanProduct;
  selectedLoanParticulars?: LoanParticulars;
  repaymentResponse?: RepaymentResponse;
  loanApplicationNotes?: LoanApplicationNotes;
  loanGuarantors?: LoanGuarantee[];
  loanGuarantorAccounts?: LoanClientAccounts[];
  loanGuarantorMembers?: Member[];
  loanCollaterals?: LoanCollateral[];
  loanReferees?: LoanReferee[];
  loanSavingsAccount?: SavingAccount;
  loanMemberOverride?: LoanMemberOverride;
  loanApplicationTemplate?: TLoanApplicationTemplate;
  loanDetails?: TLoanDetails;
  loanAppraisal?: TAppraisalDetails;
  loanApprovalLevel1?: TLoanApprovalLevel1;
  loanApprovalLevel2?: TLoanApprovalLevel2;
  loanApprovalFinal?: TLoanApprovalLevelFinal;
}

const initialState: LoanSlice = {
  loans: [],
  loanProducts: [],
};

const loanSlice = createSlice({
  name: "loan",
  initialState,
  reducers: {
    setLoanData: (state, action: PayloadAction<LoanSlice>) => {
      state = { ...state, ...action.payload }
    },
    setLoans: (state, action: PayloadAction<any[]>) => {
      state.loans = action.payload;
    },
    setLoansCount: (state, action: PayloadAction<number>) => {
      state.loansCount = action.payload;
    },
    setMemberTemplate: (state, action: PayloadAction<MemberTemplate>) => {
      state.memberTemplate = action.payload;
    },
    setLoanRecepient: (state, action: PayloadAction<any>) => {
      state.loanRecipient = action.payload;
    },
    setLoanProducts: (state, action: PayloadAction<LoanProduct[]>) => {
      state.loanProducts = action.payload;
    },
    setClientLoanTemplate: (state, action: PayloadAction<ClientLoanTemplate>) => {
      state.clientLoanTemplate = action.payload;
    },
    setSelectedLoanProduct: (state, action: PayloadAction<LoanProduct>) => {
      state.selectedLoanProduct = action.payload;
    },
    setSelectedLoanParticulars: (state, action: PayloadAction<any>) => {
      state.selectedLoanParticulars = action.payload;
    },
    setLoanRepayment: (state, action: PayloadAction<RepaymentResponse>) => {
      state.repaymentResponse = action.payload;
    },
    setLoanApplicationNotes: (state, action: PayloadAction<LoanApplicationNotes>) => {
      state.loanApplicationNotes = { ...state.loanApplicationNotes, ...action.payload };
    },
    setLoanGuarantors: (state, action: PayloadAction<LoanGuarantee[]>) => {
      state.loanGuarantors = action.payload
    },
    setLoanGuarantorAccounts: (state, action: PayloadAction<LoanClientAccounts[]>) => {
      state.loanGuarantorAccounts = action.payload
    },
    setLoanGuarantorMembers: (state, action: PayloadAction<Member[]>) => {
      state.loanGuarantorMembers = action.payload
    },
    setLoanSavingAccount: (state, action: PayloadAction<SavingAccount>) => {
      state.loanSavingsAccount = action.payload
    },
    setLoanMemberOverride: (state, action: PayloadAction<LoanMemberOverride>) => {
      state.loanMemberOverride = action.payload
    },
    setLoanRecipientAccounts: (state, action: PayloadAction<ClientAccounts>) => {
      state.loanRecipientAccounts = action.payload
    },
    setCollateralTemplate: (state, action: PayloadAction<CollateralTemplate>) => {
      state.collateralTemplate = action.payload
    },
    setGuarantorTemplate: (state, action: PayloadAction<GuarantorTemplate>) => {
      state.guarantorTemplate = action.payload
    },
    setChargeTemplate: (state, action: PayloadAction<ChargeTemplate>) => {
      state.chargeTemplate = action.payload
    },
    setLoanCollaterals: (state, action: PayloadAction<LoanCollateral[]>) => {
      state.loanCollaterals = action.payload
    },
    setLoanReferees: (state, action: PayloadAction<LoanReferee[]>) => {
      state.loanReferees = action.payload
    },
    setLoanApplicationTemplate: (state, action: PayloadAction<TLoanApplicationTemplate>) => {
      state.loanApplicationTemplate = { ...state.loanApplicationTemplate, ...action.payload }
    },
    setLoanDetails: (state, action: PayloadAction<TLoanDetails>) => {
      state.loanDetails = { ...state.loanDetails, ...action.payload }
    },
    setLoanAppraisal: (state, action: PayloadAction<TAppraisalDetails>) => {
      state.loanAppraisal = { ...state.loanAppraisal, ...action.payload }
      if (!state.loanDetails) {
        state.loanDetails = {}
      }
      if (!state.loanDetails.loanAppraisal) {
        state.loanDetails.loanAppraisal = {}
      }
      state.loanDetails.loanAppraisal = { ...state.loanDetails.loanAppraisal, ...action.payload }
    },
    setLoanApprovalLevel1: (state, action: PayloadAction<TLoanApprovalLevel1>) => {
      state.loanApprovalLevel1 = { ...state.loanApprovalLevel1, ...action.payload }
    },
    setLoanApprovalLevel2: (state, action: PayloadAction<TLoanApprovalLevel2>) => {
      state.loanApprovalLevel2 = { ...state.loanApprovalLevel2, ...action.payload }
    },
    setLoanApprovalFinal: (state, action: PayloadAction<TLoanApprovalLevelFinal>) => {
      state.loanApprovalFinal = { ...state.loanApprovalFinal, ...action.payload }
    },
    setExtraClientInfo: (state, action: PayloadAction<ExtraClientDetails | undefined>) => {
      state.clientExtraInfo = action.payload;
    },
    resetLoanState: (state) => {
      state.loanRecipient = undefined
      state.loanProducts = []
      state.loanDetails = undefined
      state.loanApplicationTemplate = undefined
      state.selectedLoanProduct = undefined
      state.selectedLoanParticulars = undefined
      state.repaymentResponse = {}
      state.loanApplicationNotes = {}
      state.loanGuarantors = []
      state.loanGuarantorAccounts = []
      state.loanGuarantorMembers = []
      state.loanSavingsAccount = {}
      state.loanMemberOverride = {}
      state.loanRecipientAccounts = {}
      state.loanCollaterals = []
      state.loanReferees = []
    },
  },
});

export const {
  setLoans,
  setLoansCount,
  setLoanRecepient,
  setLoanProducts,
  setSelectedLoanProduct,
  setClientLoanTemplate,
  setSelectedLoanParticulars,
  setLoanRepayment,
  setLoanApplicationNotes,
  setLoanGuarantors,
  setLoanGuarantorMembers,
  setLoanSavingAccount,
  setLoanMemberOverride,
  setMemberTemplate,
  setCollateralTemplate,
  setGuarantorTemplate,
  setChargeTemplate,
  setLoanRecipientAccounts,
  setLoanGuarantorAccounts,
  setLoanCollaterals,
  setLoanReferees,
  resetLoanState,
  setLoanApplicationTemplate,
  setLoanDetails,
  setLoanAppraisal,
  setLoanApprovalLevel1,
  setLoanApprovalLevel2,
  setLoanApprovalFinal,
  setExtraClientInfo,
} = loanSlice.actions;

export const fetchLoans =
  (filter: any): AppThunk =>
    async (dispatch: Dispatch) => {
      if (filter.limit) {
      }
      const response = await tkbApi.loan.fetch();
      dispatch(setLoans(response.data.pageItems));
      dispatch(setLoansCount(response.data.totalFilteredRecords));
    };

export const fetchLoanProducts =
  (filter: any): AppThunk =>
    async (dispatch: Dispatch) => {
      try {
        if (filter.limit) {
        }
        const response = await tkbApi.loan.fetchLoanProducts();
        dispatch(setLoanProducts(response.data));
      } catch (error) {

      }
    };

// export const fetchClientLoanProducts =
//   (clientId: string | number): AppThunk =>
//     async (dispatch: Dispatch) => {
//       const response = await tkbApi.loan.fetchClientLoanTemplate(clientId);
//       dispatch(setClientLoanTemplate(response.data));
//     };

export default loanSlice.reducer;
