import { SnackbarOrigin } from "@mui/material";
import { createSlice, Dispatch, type PayloadAction } from "@reduxjs/toolkit";
import { Office } from "../modules/Office/types/response/Office";
import { AppThunk } from "./store";
import { OfficeRepository } from "../modules/Office/repository/office";
import secureLocalStorage from "react-secure-storage";

export interface ISnackbar {
  snackbarActive?: boolean;
  snackbarMessage?: string;
  snackbarPosition?: SnackbarOrigin;
}

export interface ISuccessComponent {
  successActive?: boolean;
  successMessage?: string;
}

export interface AppSlice {
  snackbar: ISnackbar | null;
  success: ISuccessComponent | null;
  office?: Office;
  offices?: Office[]
}

const initialState: AppSlice = {
  snackbar: {},
  success: {},
  office: JSON.parse(secureLocalStorage.getItem('office') as string) as Office
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateSnackbar: (state, action: PayloadAction<ISnackbar>) => {
      state.snackbar = action.payload;
    },
    updateSucess: (state, action: PayloadAction<ISuccessComponent>) => {
      state.success = action.payload;
    },
    showSucess: (state, action: PayloadAction<AppSlice>) => {
      state.success = action.payload.success;
      state.snackbar = action.payload.snackbar;
    },
    setOffice: (state, action: PayloadAction<Office>) => {
      state.office = action.payload
      secureLocalStorage.setItem('office', JSON.stringify(action.payload))
    },
    setOffices: (state, action: PayloadAction<Office[]>) => {
      state.offices = action.payload
    },
  },
});

export const { updateSnackbar, setOffice, setOffices, showSucess, updateSucess } = appSlice.actions;

export const fetchOffices =
  (): AppThunk =>
    async (dispatch: Dispatch) => {
      const response = await OfficeRepository.listOffices();
      dispatch(setOffices(response));
      if (response.length > 0) {
        dispatch(setOffice(response[0]))
      }
    };


export default appSlice.reducer;
