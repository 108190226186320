import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { Member } from "../modules/Members/types/response/ListMembersResponse";
import { SavingProduct } from "../modules/Savings/types/response/ListSavingProducts";

export interface SavingStore {
	step?: number,
	subStep?: number,
	savingsProducts?: SavingProduct[]
	selectedMember?: Member,
	selectedProduct?: SavingProduct,
}

export interface MemberSlice {
	savingStore?: SavingStore
}

const initialState: MemberSlice = {};

const savingSlice = createSlice({
	name: "saving",
	initialState,
	reducers: {
		setSaving: (state, action: PayloadAction<SavingStore>) => {
			state.savingStore = { ...state.savingStore, ...action.payload }
		},
		resetSaving: (state) => {
			state.savingStore = { savingsProducts: state.savingStore?.savingsProducts }
		},
	},
});

export const { setSaving, resetSaving } = savingSlice.actions;

export default savingSlice.reducer;
