import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getUserDetails, setUserDetails, setToken, getToken } from "../utils/helpers";
import { AuthResponse, Role } from "../modules/Auth/types/Auth";

export interface IAuthUser {
    username: string;
    officeId: number;
    userId: number;
    roles?: any[];
}

export interface AuthSlice {
    user?: IAuthUser;
    authLoading: boolean;
    token?: string;
    globalMsg?: string;
    cashierId?: number;
    tellerId?: number;
    isAuthenticated: boolean;
    roles?: Role[];
}

const initialState: AuthSlice = {
    user: getUserDetails() as IAuthUser,
    authLoading: true,
    token: getToken(),
    isAuthenticated: false,
    roles: [],
};

export interface LoginInfo {
    auth?: AuthResponse;
    msg?: string
    cashierId?: number;
    tellerId?: number;
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<IAuthUser>) => {
            state.user = action.payload;
            setUserDetails(action.payload);
        },
        setAuthLoading: (state, action: PayloadAction<boolean>) => {
            state.authLoading = action.payload;
        },
        logout: (state) => {
            state.user = undefined;
            setUserDetails(null);
        },
        login: (state, action: PayloadAction<LoginInfo>) => {
            state.globalMsg = action.payload.msg;
            state.cashierId = action.payload.cashierId;
            state.tellerId = action.payload.tellerId;
            state.token = action.payload.auth?.base64EncodedAuthenticationKey;
            state.isAuthenticated = true;
            state.authLoading = false;
            const user: IAuthUser = {
                username: action.payload.auth?.username!,
                officeId: action.payload.auth?.officeId!,
                userId: action.payload.auth?.userId!,
                roles: action.payload.auth?.roles!,
            };

            state.user = user;
            state.roles = action.payload.auth?.roles;

            setUserDetails(user);
            setToken(action.payload.auth?.base64EncodedAuthenticationKey!);
        },
    },
});

export const { setUser, logout, setAuthLoading, login } = authSlice.actions;

export default authSlice.reducer;
