import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../redux/store";
import { IRoute } from "./types";

export default function useDashboardRoutes() {
  const { user } = useSelector((state: RootState) => state.auth)

  const location = useLocation();

  // Dashboard routes - Filter based on role
  const routes: IRoute[] = [
    {
      key: "add-new",
      label: "Add New",
      path: "/dashboard",
      icon: "Add New@2x.png",
      roles: []
    }, {
      key: "add-new",
      label: "Welcome",
      path: "/dashboard/cashier",
      icon: "Add New@2x.png",
      roles: ['Cashier Role']
    },
    {
      key: "dashboard",
      label: "Dashboard",
      path: "/analytics",
      icon: "Dashboard@2x.png",
      roles: ['Super user']
    },
    {
      key: "members",
      label: "Members",
      path: "/members",
      icon: "Members@2x.png",
      roles: ['Super user', 'Cashier Role']
    },
    {
      key: "loans",
      label: "Loans",
      path: "/loans",
      icon: "myloans@2x.png",
      roles: ['Super user', 'Cashier Role']
    },
    {
      key: "savings",
      label: "Savings",
      path: "/savings",
      icon: "mysavings@2x.png",
      roles: ['Super user', 'Cashier Role']
    },
    {
      key: "tellers",
      label: "Tellers",
      path: "/tellers",
      icon: "Teller@2x.png",
      roles: ['Super user']
    },
    {
      key: "staff",
      label: "Staff",
      path: "/staff",
      icon: "Members@2x.png",
      roles: ['Super user']
    },
    // Other Member routes hidden
    {
      hidden: true,
      path: "/members/add",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/edit",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/close",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/list",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/*",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/*/savings/*",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/*/savings/*/journals",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/*/savings/*/journals/*",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/*/loans/*",
      roles: ['Super user', 'Cashier Role']
    },
    // Other teller routes
    {
      hidden: true,
      path: "/tellers/*",
      roles: ['Super user']
    },
    {
      hidden: true,
      path: "/tellers/deposit",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/tellers/withdraw",
      roles: ['Super ', 'Cashier Role']
    },
    // Other Loans routes
    {
      hidden: true,
      path: "/loans/add",
      roles: ['Super user']
    },
    // Other Loans routes
    {
      key: "reports",
      label: "Reports",
      path: "/reports",
      icon: "Dashboard@2x.png",
      roles: ['Super user']
    },
    {
      key: "system",
      label: "System",
      icon: "Settings.png",
      roles: ['Super user', 'Cashier Role'],
      children: [
        {
          key: "roles",
          label: "Roles",
          path: "/roles",
          icon: "Members@2x.png",
          roles: ['Super user', 'Cashier Role']
        },
        {
          hidden: true,
          path: "/roles/*",
          roles: ['Super user', 'Cashier Role']
        },
      ]
    },
  ].filter(role => {
    if (role.roles?.length === 0) {
      return true
    }
    for (const urole of (user?.roles ?? [])) {
      if (role.roles?.includes(urole.name)) {
        return true;
      }
    }
    return false;
  });


  function allowedRoute(currentRoutes: IRoute[]): boolean {
    return (currentRoutes || routes).some(route => {
      if (route.path === location.pathname) {
        return true
      }
      if (route.path?.includes('*')) {
        const regex = new RegExp('^' + route.path.replace(/\*/g, '.*') + '$');
        return regex.test(location.pathname);
      }
      if ((route.children?.length ?? 0) > 0) {
        return allowedRoute(route.children ?? [])
      }
      return false;
    })
  }

  return { routes, allowedRoute }
}