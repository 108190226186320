import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

declare global {
  interface Window {
    logout: any;
  }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// Dynamically load Tableau's Embedding API script
const loadTableauScript = () => {
  const script = document.createElement("script");
  script.type = "module";
  script.src = "https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js";
  script.async = true;

  // Append the script to the document
  document.body.appendChild(script);

  script.onload = () => {
    console.log("Tableau Embedding API script loaded successfully.");
  };

  script.onerror = () => {
    console.error("Failed to load the Tableau Embedding API script.");
  };
};

// Call the function to load the script before rendering the app
loadTableauScript();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
