import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { UserTemplate } from "../modules/Staff/types/responses/Template";

export type NewUser = {
	id?: string | number;
	sendPasswordToEmail?: boolean;
	username?: string;
	joiningDate?: Date;
	firstname?: string;
	secondname?: string;
	lastname?: string;
	email?: string;
	phone?: string;
	isLoanOfficer?: boolean;
	passwordNeverExpires?: boolean;
	password?: string;
	repeatPassword?: string;
	officeId?: number;
	rolesId?: number[] | string[];
	officeNames?: string;
	staffId?: number;
	staffNames?: string;
};

export interface UserInfo {
	userTemplate?: UserTemplate;
	newUser?: NewUser,
}

export interface UserSlice {
	userInfo?: UserInfo
}

const initialState: UserSlice = {};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setNewUser: (state, action: PayloadAction<UserInfo>) => {
			state.userInfo = { ...state.userInfo, ...action.payload }
		},
		resetUser: (state) => {
			state.userInfo = {
				userTemplate: state.userInfo?.userTemplate,
			}
		},
	},
});

export const { setNewUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
