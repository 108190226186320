import { useState, useEffect, lazy, type LazyExoticComponent, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingScreen from "./components/LoadingScreen";
import DashboardLayout from "./layout/DashboardLayout";
import FullPageLayout from "./layout/FullPageLayout";
import { useAppDispatch, type RootState } from "./redux/store";
import { logout } from "./redux/authSlice";

function Loadable(Component: LazyExoticComponent<any>): JSX.Element {
	return (
		<Suspense fallback={<LoadingScreen />}>
			<Component />
		</Suspense>
	);
}

const Login = Loadable(lazy(() => import("./modules/Auth/Login")));
const MainDashboard = Loadable(lazy(() => import("./modules/Dashboard/MainDashboard")));
const CashierDashboard = Loadable(lazy(() => import("./modules/Dashboard/CashierDashboard")));

// Member pages
const MembersLanding = Loadable(lazy(() => import("./modules/Members/pages/landing")));
const MembersPage = Loadable(lazy(() => import("./modules/Members/pages/members")));
const ViewMember = Loadable(lazy(() => import("./modules/Members/pages/viewMember")));
const EditMember = Loadable(lazy(() => import("./modules/Members/pages/editMember")));
const MemberSaving = Loadable(lazy(() => import("./modules/Members/pages/memberSaving")));
const MemberSavingJournalEntries = Loadable(lazy(() => import("./modules/Members/pages/savingJournalEntries")));
const MemberSavingJournalTransaction = Loadable(lazy(() => import("./modules/Members/pages/savingJournalTransactions")));
const CloseMember = Loadable(lazy(() => import("./modules/Members/pages/closeMember")));
const MemberLoan = Loadable(lazy(() => import("./modules/Members/pages/memberLoan")));

// Loans pages
const ListLoans = Loadable(lazy(() => import("./modules/Loan/ListLoans")));
const AddLoanPage = Loadable(lazy(() => import("./modules/Loan/AddLoan")));

// Savings pages
const SavingsPage = Loadable(lazy(() => import("./modules/Savings/Savings")));
const AddMember = Loadable(lazy(() => import("./modules/Members/pages/addMember")));
const AddSavings = Loadable(lazy(() => import("./modules/Savings/AddSavings")));

// Tills and Tellers
const ListTills = Loadable(lazy(() => import("./modules/Tills/ListTills")));
const ListTellers = Loadable(lazy(() => import("./modules/Tellers/pages/tellers")));
const TellerInfo = Loadable(lazy(() => import("./modules/Tellers/pages/tellerInfo")));
const CashierInfo = Loadable(lazy(() => import("./modules/Tellers/pages/cashierInfo")));
const DepositSaving = Loadable(lazy(() => import("./modules/Tellers/pages/depositSaving")));
const WithdrawSaving = Loadable(lazy(() => import("./modules/Tellers/pages/withdrawSaving")));
const AddTransaction = Loadable(lazy(() => import("./modules/Tills/AddTransaction")));

// Staff and Users
const ListStaffs = Loadable(lazy(() => import("./modules/Staff/pages/staffs")));

// Roles
const ListRoles = Loadable(lazy(() => import("./modules/Roles/pages/viewRoles")));
const ViewRole = Loadable(lazy(() => import("./modules/Roles/pages/viewRole")));

const Analytics = Loadable(lazy(() => import("./modules/Dashboard/Analytics")));

const Reports = Loadable(lazy(() => import("./modules/Reports/pages/home")));

function ProtectedRoute({ children, user }: { children: JSX.Element; user: any }) {
	return user ? children : <Navigate to="/" replace />;
}

function Router(): JSX.Element {
	const dispatch = useAppDispatch();
	const [isInitialized, setInitialized] = useState(false);
	const { user } = useSelector((state: RootState) => state.auth);

	window.logout = () => {
		dispatch(logout());
	};

	useEffect(() => {
		if (!isInitialized) {
			setInitialized(true);
		}
	}, [isInitialized]);

	if (!isInitialized) {
		return <LoadingScreen />;
	}

	return (
		<BrowserRouter>
			<Routes>
				{/* Public Routes */}
				<Route path="/" element={user ? <Navigate to="/dashboard" replace /> : <FullPageLayout />}>
					<Route path="" element={Login} />
				</Route>

				{/* Protected Routes */}
				<Route
					path="members"
					element={<ProtectedRoute user={user} children={<DashboardLayout />} />}
				>
					<Route path="" element={MembersLanding} />
					<Route path="add" element={AddMember} />
					<Route path="edit" element={EditMember} />
					<Route path="close" element={CloseMember} />
					<Route path="list" element={MembersPage} />
					<Route path=":memberId" element={ViewMember} />
					<Route path=":memberId/savings/:savingId" element={MemberSaving} />
					<Route path=":memberId/savings/:savingId/journals" element={MemberSavingJournalEntries} />
					<Route path=":memberId/savings/:savingId/journals/:transactionId" element={MemberSavingJournalTransaction} />
					<Route path=":memberId/loans/:loanId" element={MemberLoan} />
				</Route>

				{/* Protect /dashboard route */}
				<Route
					path="dashboard"
					element={<ProtectedRoute user={user} children={<DashboardLayout />} />}
				>
					<Route path="" element={MainDashboard} />
					<Route path="cashier" element={CashierDashboard} />
				</Route>

				<Route path="loans" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={ListLoans} />
					<Route path="add" element={AddLoanPage} />
				</Route>

				<Route path="analytics" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={Analytics} />
				</Route>

				<Route path="savings" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={SavingsPage} />
					<Route path="add" element={AddSavings} />
				</Route>

				<Route path="tills" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={ListTills} />
					<Route path="add-transaction" element={AddTransaction} />
				</Route>

				<Route path="tellers" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={ListTellers} />
					<Route path=":tellerId" element={TellerInfo} />
					<Route path=":tellerId/cashiers/:cashierId" element={CashierInfo} />
					<Route path="deposit" element={DepositSaving} />
					<Route path="withdraw" element={WithdrawSaving} />
				</Route>

				<Route path="staff" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={ListStaffs} />
				</Route>

				<Route path="roles" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={ListRoles} />
					<Route path=":roleId" element={ViewRole} />
				</Route>

				<Route path="reports" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={Reports} />
				</Route>

				<Route path="*" element={<Navigate to="/dashboard" />} />
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
