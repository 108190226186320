import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  AppBar as muiAppBar,
  Box,
  IconButton,
  styled,
  Toolbar,
  InputAdornment,
  FormControl,
  OutlinedInput,
  useMediaQuery,
  useTheme,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PortalLogo from "../components/PortalLogo";
import SideNav from "./components/SideNav";
import UserMenu from "./components/UserMenu";
import useDashboardRoutes from "./dashboardRoutes";

const drawerWidth = 200;

function DashboardLayout(): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { routes, allowedRoute } = useDashboardRoutes()

  const toolbarHeight = !isMobile ? 56 : 64;

  const [open, setOpen] = useState(!isMobile);
  const [isSearching, showSearch] = useState(false);

  const AppBar = styled(muiAppBar)(({ theme }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }));

  const MainContent = styled("main")(({ theme }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: `${toolbarHeight}px`,
    paddingTop: "18px",
    ...(!isMobile &&
      open && {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.complex,
      }),
    }),
  }));

  useEffect(() => {
    if (!isMobile) {
      setOpen(true);
    }
  }, [isMobile]);

  return (
    <Box display="flex">
      <AppBar
        color="inherit"
        position="fixed"
        elevation={isMobile ? 1 : 0}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {!(isMobile && isSearching) && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                aria-label="open drawer"
                onClick={() => {
                  setOpen(!open);
                }}
                edge="start"
                sx={{ mr: 2, ...(!isMobile && { display: "none" }) }}
              >
                {open ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
              <PortalLogo height={50} />
              {!isMobile && isSearching && (
                <Box sx={{ ml: 6, width: 500 }}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <OutlinedInput
                      id="search"
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon color="primary" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              )}
            </Box>
          )}
          {!(isMobile && isSearching) && (
            <Box sx={{ display: "flex", gap: 1 }}>
              {isMobile && (
                <IconButton color="primary" sx={{ p: 0 }} onClick={() => showSearch(true)}>
                  <SearchIcon />
                </IconButton>
              )}
              <IconButton color="primary" sx={{ p: 0 }}>
                <NotificationsIcon />
              </IconButton>
              <UserMenu />
            </Box>
          )}
          {isMobile && isSearching && (
            <FormControl size="small" fullWidth variant="outlined">
              <OutlinedInput
                id="search"
                placeholder="Search..."
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="search" onClick={() => showSearch(false)} edge="end">
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
        </Toolbar>
      </AppBar>
      <SideNav
        open={open}
        onToggleNav={() => {
          setOpen(!open);
        }}
        drawerWidth={drawerWidth}
        isMobile={isMobile}
        routes={routes.filter(route => !route.hidden)}
      />
      <MainContent sx={{ flexGrow: 1, pb: 6 }}>
        {allowedRoute(routes) ? <Outlet /> : <Alert severity="warning">Not enough permissions</Alert>}
      </MainContent>
    </Box>
  );
}

export default DashboardLayout;
