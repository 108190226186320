import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { Member } from "../modules/Members/types/response/ListMembersResponse";
import { ClientAccounts, SavingsAccount } from "../modules/Savings/types/response/ListClientSavings";
import { TSavingTransactionTemplate } from "../modules/Savings/types/response/SavingTemplate";

export type ICreateTeller = {
	officeId?: number;
	name?: string;
	description?: string;
	endDate?: Date;
	status?: number;
	locale?: string;
	dateFormat?: string;
	startDate?: Date;
}

export type TDepositSavings = {
	memberInfo?: Member;
	clientAccounts?: ClientAccounts;
	savingAccount?: SavingsAccount;
	savingTemplate?: TSavingTransactionTemplate;
	transactionDate?: Date;
	transactionAmount?: number;
	paymentTypeId?: number;
	account?: string;
	cheque?: string;
	routingCode?: string;
	receipt?: string;
	bank?: string;
	notes?: string;
}

export interface TellerStore {
	step?: number,
	subStep?: number,
	createTeller?: ICreateTeller
	tellerTransaction?: TDepositSavings
}

export interface TellerSlice {
	tellerStore?: TellerStore
}

const initialState: TellerSlice = {
	tellerStore: {}
};

const tellerSlice = createSlice({
	name: "teller",
	initialState,
	reducers: {
		setTeller: (state, action: PayloadAction<TellerStore>) => {
			state.tellerStore = { ...state.tellerStore, ...action.payload }
		},
		setTellerTransaction: (state, action: PayloadAction<TDepositSavings>) => {
			state.tellerStore!.tellerTransaction = { ...state.tellerStore?.tellerTransaction, ...action.payload }
		},
		resetTeller: (state) => {
			state.tellerStore = {}
		},
	},
});

export const { setTeller, resetTeller, setTellerTransaction } = tellerSlice.actions;

export default tellerSlice.reducer;
