import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { PermissionUsageDatum } from "../modules/Roles/types/response/Role";

export type IRoleData = {
  roleId?: number;
  name?: string;
  description?: string;
  permissions?: PermissionUsageDatum[]
}

export interface SystemStore {
  step?: number,
  subStep?: number,
  role?: IRoleData
}

export interface SystemSlice {
  data?: SystemStore
}

const initialState: SystemSlice = {
  data: {}
};

const tellerSlice = createSlice({
  name: "teller",
  initialState,
  reducers: {
    setSystemData: (state, action: PayloadAction<SystemStore>) => {
      state.data = { ...state.data, ...action.payload }
    },
    resetSystemData: (state) => {
      state.data = {}
    },
  },
});

export const { setSystemData, resetSystemData } = tellerSlice.actions;

export default tellerSlice.reducer;
